<!--
 * @Description: 后台新闻菜单
 * @Autor: YuYang
 * @Date: 2024-03-05 16:00:34
 * @LastEditors: YuYang
 * @LastEditTime: 2024-03-05 16:00:34
-->
<template>
  <SideBar :groups='groups'></SideBar>
</template>

<script>
import SideBar from "@/components/SideBar";
export default {
  components: {
    SideBar,
  },

  data() {
    return {
      groups: [
        {
          label: "新闻信息",
          list: [
            {
              name: "全部新闻",
              path: "/mall/news/news-manager",
            },
            // {
            //   name: "全部分类",
            //   path: "/mall/news/group-manager",
            // },
          ],
        },
      ],
    };
  },
};
</script>